// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-alert-mdx": () => import("./../../src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-button-mdx": () => import("./../../src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-getting-started-gitsecret-md": () => import("./../../src/getting-started/gitsecret.md" /* webpackChunkName: "component---src-getting-started-gitsecret-md" */),
  "component---src-getting-started-gitversion-md": () => import("./../../src/getting-started/gitversion.md" /* webpackChunkName: "component---src-getting-started-gitversion-md" */),
  "component---src-getting-started-links-md": () => import("./../../src/getting-started/links.md" /* webpackChunkName: "component---src-getting-started-links-md" */),
  "component---src-index-mdx": () => import("./../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-infrastructure-ci-cd-index-md": () => import("./../../src/Infrastructure/CI-CD/index.md" /* webpackChunkName: "component---src-infrastructure-ci-cd-index-md" */),
  "component---src-infrastructure-hybrit-index-md": () => import("./../../src/Infrastructure/hybrit/index.md" /* webpackChunkName: "component---src-infrastructure-hybrit-index-md" */),
  "component---src-infrastructure-index-md": () => import("./../../src/Infrastructure/index.md" /* webpackChunkName: "component---src-infrastructure-index-md" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-projects-bouwkennis-index-md": () => import("./../../src/projects/bouwkennis/index.md" /* webpackChunkName: "component---src-projects-bouwkennis-index-md" */),
  "component---src-projects-index-md": () => import("./../../src/projects/index.md" /* webpackChunkName: "component---src-projects-index-md" */),
  "component---src-projects-insiderjobs-index-md": () => import("./../../src/projects/insiderjobs/index.md" /* webpackChunkName: "component---src-projects-insiderjobs-index-md" */),
  "component---src-projects-monitoring-index-md": () => import("./../../src/projects/monitoring/index.md" /* webpackChunkName: "component---src-projects-monitoring-index-md" */),
  "component---src-projects-peinemann-index-md": () => import("./../../src/projects/Peinemann/index.md" /* webpackChunkName: "component---src-projects-peinemann-index-md" */),
  "component---src-projects-shantisom-index-md": () => import("./../../src/projects/shantisom/index.md" /* webpackChunkName: "component---src-projects-shantisom-index-md" */),
  "component---src-projects-spock-index-md": () => import("./../../src/projects/spock/index.md" /* webpackChunkName: "component---src-projects-spock-index-md" */),
  "component---src-projects-tencategrass-index-md": () => import("./../../src/projects/tencategrass/index.md" /* webpackChunkName: "component---src-projects-tencategrass-index-md" */),
  "component---src-projects-trustanbouw-index-md": () => import("./../../src/projects/trustanbouw/index.md" /* webpackChunkName: "component---src-projects-trustanbouw-index-md" */),
  "component---src-projects-viscon-index-md": () => import("./../../src/projects/viscon/index.md" /* webpackChunkName: "component---src-projects-viscon-index-md" */),
  "component---src-projects-viscon-supplier-portal-index-md": () => import("./../../src/projects/viscon-supplier-portal/index.md" /* webpackChunkName: "component---src-projects-viscon-supplier-portal-index-md" */),
  "component---src-standards-basics-index-md": () => import("./../../src/standards/Basics/index.md" /* webpackChunkName: "component---src-standards-basics-index-md" */),
  "component---src-standards-index-md": () => import("./../../src/standards/index.md" /* webpackChunkName: "component---src-standards-index-md" */),
  "component---src-standards-kotlin-index-md": () => import("./../../src/standards/Kotlin/index.md" /* webpackChunkName: "component---src-standards-kotlin-index-md" */),
  "component---src-standards-linting-index-md": () => import("./../../src/standards/Linting/index.md" /* webpackChunkName: "component---src-standards-linting-index-md" */),
  "component---src-standards-mulesoft-index-md": () => import("./../../src/standards/Mulesoft/index.md" /* webpackChunkName: "component---src-standards-mulesoft-index-md" */),
  "component---src-standards-react-index-md": () => import("./../../src/standards/React/index.md" /* webpackChunkName: "component---src-standards-react-index-md" */),
  "component---src-standards-testing-index-md": () => import("./../../src/standards/Testing/index.md" /* webpackChunkName: "component---src-standards-testing-index-md" */)
}

